import { v4 as uuidv4 } from 'uuid';
import { Reducer } from 'redux';

import {
  ByggType,
  DetailingStep,
  JaNeiVetIkke,
  OppvarmingBruk,
  PlasseringAvEnhetIByggVertikalt,
  TilliggendeRom,
  Varmekilde,
  Varmesentral,
  VentilasjonsType
} from '../../components/registering/utils/registerEnums';
import { EnergyPlan } from '../../types/building';
import {
  Etasje,
  Konstruksjonstetthet,
  OppvarmingSol,
  OppvarmingVarmepumpe,
  Terrengskjerming,
  UserInput,
  Vindu
} from '../../types/registration/userInput';
import {
  energySourceUnitMap,
  TekniskUtstyrKey,
  TekniskUtstyrKeys
} from '../../utils/register';
import { sanitize } from '../../utils/sanitize';
import { User } from '../../types/user';

import {
  RegistrationAction,
  RegistrationActionTypes,
  RegistrationStore
} from './types';
import { updateBeregningskjerneinput } from './utils';

const initStateTransformer = (
  userInput?: UserInput,
  energiplan?: Partial<EnergyPlan>,
  user?: User
): RegistrationStore => {
  if (!userInput) {
    return {
      allFloorsSameShape: true,
      allFloorsSameWallConstruction: true,
      allWallsAgainstTheOpen: false,
      userInput: {
        bygningsdetaljer: {
          etasjer: []
        },
        detaljeringssteg: {
          bygningsform: false,
          energibruk: false,
          vegger: false,
          vinduer: false,
          gulv: false,
          lekkasjetall: false,
          takkonstruksjon: false,
          ytterdorer: false
        }
      }
    };
  }

  const lekkasjetallDato =
    userInput?.bygningsdetaljer?.konstruksjonstetthet?.dato;

  const mappedUserInput: UserInput = {
    ...userInput,
    bygningsdetaljer: {
      ...userInput?.bygningsdetaljer,
      // If date for leakage number measurement is available this needs to be casted back to a date
      // instead of a string. This huge userInput object needs to be refactored.
      konstruksjonstetthet: {
        ...userInput?.bygningsdetaljer?.konstruksjonstetthet,
        dato: lekkasjetallDato ? new Date(lekkasjetallDato) : undefined
      }
    }
  };

  const sanitizedInitValues = sanitize(mappedUserInput);

  // allFloorsSameShape, allFloorsSameWallConstruction and allWallsAgainstTheOpen should
  // probably be looked at. Dont know why the client should have to loop all of this data to
  // be able to set those three properties. Maybe these properties should be sent from the backend.

  const allFloorsSameShape = sanitizedInitValues.bygningsdetaljer?.etasjer
    ? Array.from(
        new Set(
          sanitizedInitValues.bygningsdetaljer?.etasjer?.map((etasje) =>
            [
              etasje.bygningsForm,
              ...(etasje.vegger?.map(
                (vegg) =>
                  `${vegg.veggId}${vegg.himmelretning}${vegg.lengdeIMeter}${etasje.gjennomsnittligEtasjehoyde}`
              ) || [])
            ].join()
          )
        )
      ).length === 1
    : true;

  const allFloorsSameWallConstruction = allFloorsSameShape
    ? sanitizedInitValues.bygningsdetaljer?.etasjer
      ? Array.from(
          new Set(
            sanitizedInitValues.bygningsdetaljer?.etasjer?.map((etasje) =>
              [
                etasje.bygningsForm,
                ...(etasje.vegger?.map(
                  (vegg) =>
                    `${vegg.veggId}${vegg.himmelretning}${vegg.veggSoner
                      .map((veggsone) =>
                        JSON.stringify(veggsone, (key, value) =>
                          key !== 'id' && key !== 'vinduer' ? value : undefined
                        )
                      )
                      .join('')}`
                ) || [])
              ].join()
            )
          )
        ).length === 1
      : true
    : false;

  const allWallsAgainstTheOpen =
    !sanitizedInitValues.bygningsdetaljer?.etasjer?.some((etasje) =>
      etasje.vegger?.some((vegg) =>
        vegg.veggSoner?.some(
          (vs) => vs.tilliggendeRom !== TilliggendeRom.MotDetFri
        )
      )
    );

  return {
    allFloorsSameShape,
    allFloorsSameWallConstruction,
    allWallsAgainstTheOpen,
    energiplan: energiplan ?? { energiplanId: uuidv4() },
    userInput: sanitizedInitValues,
    user
  };
};

const recursivelyHandleNestedChange = <T>(
  object: Record<string, unknown> = {},
  path: string[],
  value: unknown
): T => {
  if (path.length === 1 && value === undefined) {
    delete object[path[0]];
    return object as T;
  }

  const key = path.shift();

  return (
    key != null
      ? {
          ...object,
          [key]:
            path.length > 0
              ? recursivelyHandleNestedChange(
                  object[key] as Record<string, unknown>,
                  path,
                  value
                )
              : value
        }
      : value
  ) as T;
};

const registerReducer: Reducer<RegistrationStore, RegistrationActionTypes> = (
  state = initStateTransformer({} as UserInput),
  action
) => {
  switch (action.type) {
    case RegistrationAction.UPDATE_PARAMETERS: {
      if (!action.payload.data) {
        return state;
      }

      const { data } = action.payload;

      return {
        ...state,
        energiplan: {
          ...state.energiplan,
          energimerke: {
            ...state?.energiplan?.energimerke,
            inndata: {
              ...state?.energiplan?.energimerke?.inndata,
              beregningskjerneInput: updateBeregningskjerneinput(
                state.energiplan?.energimerke?.inndata?.beregningskjerneInput,
                data
              )
            }
          }
        } as EnergyPlan
      };
    }

    case RegistrationAction.WALL_HAS_NO_WINDOWS: {
      const { etasjeId, veggId, value } = action.payload;

      const updatedEtasjer = state.userInput.bygningsdetaljer.etasjer.map(
        (etasje) => {
          if (etasje.id === etasjeId) {
            const updatedVegger = etasje.vegger?.map((vegg) => {
              if (vegg.veggId === veggId) {
                return { ...vegg, hasWindows: value };
              }

              return vegg;
            });

            return { ...etasje, vegger: updatedVegger };
          }

          return etasje;
        }
      );

      return {
        ...state,
        userInput: {
          ...state.userInput,
          bygningsdetaljer: {
            ...state.userInput.bygningsdetaljer,
            etasjer: updatedEtasjer
          }
        }
      };
    }

    case RegistrationAction.INIT_REGISTRATION: {
      return initStateTransformer({} as UserInput, {}, state.user);
    }

    case RegistrationAction.UPDATE_REGISTRATION: {
      const {
        payload: { energiplan = {}, userInput = {} as UserInput }
      } = action;

      const transformedState = initStateTransformer(
        userInput,
        energiplan,
        state.user
      );

      // TODO: Fix duplicated logic.
      // initStateTransformer programmatically flips the allFloorsSameShape flag if floors are identical
      // - need to update calculatedArea
      const firstEtasjeId =
        transformedState.userInput?.bygningsdetaljer?.etasjer?.[0].id;
      const firstEtasjeCalculatedArea = {
        [firstEtasjeId]: state?.calculatedArea?.[firstEtasjeId]
      };

      // Remove all floors except the first one when all floors are toggled to be the same shape
      return {
        calculatedArea: transformedState.allFloorsSameShape
          ? firstEtasjeCalculatedArea
          : state.calculatedArea,
        ...transformedState
      };
    }

    case RegistrationAction.INIT_SOKNAD: {
      const {
        payload: {
          energiplan = {},
          userInput = {
            detaljeringssteg: {
              bygningsform: true,
              takkonstruksjon: true,
              vegger: true,
              vinduer: true,
              gulv: true,
              ytterdorer: true,
              energibruk: false,
              lekkasjetall: true
            }
          } as UserInput
        }
      } = action;

      return initStateTransformer(userInput, energiplan, state.user);
    }

    case RegistrationAction.UPDATE_CALCULATED_AREA: {
      const { area, etasjeId } = action.payload;

      return {
        ...state,
        calculatedArea: {
          ...state.calculatedArea,
          [etasjeId]: area
        }
      };
    }

    case RegistrationAction.DOR_ADD: {
      const {
        payload: { dor }
      } = action;

      return {
        ...state,
        userInput: {
          ...state.userInput,
          bygningsdetaljer: {
            ...state.userInput?.bygningsdetaljer,
            ytterDorer: [
              ...(state.userInput?.bygningsdetaljer?.ytterDorer || []),
              dor
            ]
          }
        }
      };
    }

    case RegistrationAction.DOR_REMOVE: {
      const {
        payload: { dorId }
      } = action;

      return {
        ...state,
        userInput: {
          ...state.userInput,
          bygningsdetaljer: {
            ...state.userInput?.bygningsdetaljer,
            ytterDorer: state.userInput?.bygningsdetaljer?.ytterDorer?.filter(
              (dor) => dor.id !== dorId
            )
          }
        }
      };
    }

    case RegistrationAction.ETASJE_UPDATE: {
      const { payload: updatedEtasje } = action;

      return {
        ...state,
        userInput: {
          ...state.userInput,
          bygningsdetaljer: {
            ...state.userInput?.bygningsdetaljer,
            etasjer: state.userInput?.bygningsdetaljer?.etasjer?.map(
              (etasje) => {
                if (updatedEtasje.id === etasje.id) {
                  return updatedEtasje;
                }
                return etasje;
              }
            )
          }
        }
      };
    }

    case RegistrationAction.ETASJE_VEGGSONE_ADD: {
      const {
        payload: { etasjeId, veggId, veggsone }
      } = action;

      const newVeggsone = {
        ...veggsone,
        id: uuidv4()
      };

      return {
        ...state,
        userInput: {
          ...state.userInput,
          bygningsdetaljer: {
            ...state.userInput?.bygningsdetaljer,
            etasjer: state.userInput?.bygningsdetaljer?.etasjer?.map((etasje) =>
              etasje.id === etasjeId
                ? {
                    ...etasje,
                    vegger: etasje.vegger?.map((vegg) =>
                      vegg.veggId === veggId
                        ? {
                            ...vegg,
                            veggSoner: [...vegg.veggSoner, newVeggsone]
                          }
                        : vegg
                    )
                  }
                : etasje
            )
          }
        }
      };
    }

    case RegistrationAction.ETASJE_VEGG_SET_HAS_MULTIPLE_ZONES: {
      const {
        payload: { etasjeId, veggId, hasMultipleZones }
      } = action;

      return {
        ...state,
        userInput: {
          ...state.userInput,
          bygningsdetaljer: {
            ...state.userInput?.bygningsdetaljer,
            etasjer: state.userInput?.bygningsdetaljer?.etasjer?.map((etasje) =>
              etasje.id === etasjeId
                ? {
                    ...etasje,
                    vegger: etasje.vegger?.map((vegg) =>
                      vegg.veggId === veggId
                        ? {
                            ...vegg,
                            veggSoner: hasMultipleZones
                              ? [
                                  ...vegg.veggSoner,
                                  {
                                    id: uuidv4(),
                                    ...(state.allWallsAgainstTheOpen
                                      ? {
                                          tilliggendeRom:
                                            TilliggendeRom.MotDetFri
                                        }
                                      : {})
                                  }
                                ]
                              : vegg.veggSoner?.splice(0, 1)?.map((vs) => ({
                                  ...vs,
                                  lengdeIMeter: vegg.lengdeIMeter
                                }))
                          }
                        : vegg
                    )
                  }
                : etasje
            )
          }
        }
      };
    }

    case RegistrationAction.ETASJE_VEGGSONE_REMOVE: {
      const {
        payload: { veggsoneId }
      } = action;

      return {
        ...state,
        userInput: {
          ...state.userInput,
          bygningsdetaljer: {
            ...state.userInput?.bygningsdetaljer,
            etasjer: state.userInput?.bygningsdetaljer?.etasjer?.map(
              (etasje) => ({
                ...etasje,
                vegger: etasje.vegger?.map((vegg) => ({
                  ...vegg,
                  veggSoner: vegg.veggSoner?.filter(
                    (vs) => vs.id !== veggsoneId
                  )
                }))
              })
            )
          }
        }
      };
    }

    case RegistrationAction.ETASJE_VEGGSONE_UPDATE: {
      const {
        payload: { veggsone }
      } = action;

      return {
        ...state,
        userInput: {
          ...state.userInput,
          bygningsdetaljer: {
            ...state.userInput?.bygningsdetaljer,
            etasjer: state.userInput?.bygningsdetaljer?.etasjer?.map(
              (etasje) => ({
                ...etasje,
                vegger: etasje.vegger?.map((vegg) => ({
                  ...vegg,
                  veggSoner: vegg.veggSoner?.map((vs) => {
                    if (vs.id !== veggsone.id) {
                      return vs;
                    }

                    return {
                      ...veggsone,
                      id: vs.id,
                      vinduer: vs.vinduer
                    };
                  })
                }))
              })
            )
          }
        }
      };
    }

    case RegistrationAction.VEGGKONSTRUKSJON_COPY: {
      const { etasjeId, fraVeggsoneId, tilVeggsoneId } = action.payload;

      const etasje = state.userInput.bygningsdetaljer.etasjer.find(
        (etg) => etg.id === etasjeId
      );

      // Get wall construction from selected wall zone (fraVeggsoneId)
      const copiedVeggkonstruksjon = etasje?.vegger
        ?.flatMap((vegg) => vegg.veggSoner)
        .find((veggsone) => veggsone.id === fraVeggsoneId)?.veggKonstruksjon;

      if (copiedVeggkonstruksjon === null) {
        return { ...state };
      }

      return {
        ...state,
        userInput: {
          ...state.userInput,
          bygningsdetaljer: {
            ...state.userInput.bygningsdetaljer,
            etasjer: state.userInput?.bygningsdetaljer?.etasjer?.map((etg) => ({
              ...etg,
              vegger: etg.vegger?.map((vegg) => ({
                ...vegg,
                veggSoner: vegg.veggSoner?.map((veggsone) => {
                  // Update target wall zone with copied wall construction (tilVeggsoneId)
                  if (veggsone.id === tilVeggsoneId) {
                    return {
                      ...veggsone,
                      veggKonstruksjon: copiedVeggkonstruksjon
                    };
                  }
                  return veggsone;
                })
              }))
            }))
          }
        }
      };
    }

    case RegistrationAction.ETASJER_UPDATE: {
      const {
        payload: { etasje }
      } = action;

      return {
        ...state,
        userInput: {
          ...state.userInput,
          bygningsdetaljer: {
            ...state.userInput?.bygningsdetaljer,
            etasjer: state.userInput?.bygningsdetaljer?.etasjer?.map((e) => {
              return {
                ...e,
                gjennomsnittligEtasjehoyde: etasje.gjennomsnittligEtasjehoyde,
                vegger: etasje.vegger,
                bygningsForm: etasje.bygningsForm,
                id: e.id ?? uuidv4()
              };
            })
          }
        }
      };
    }

    case RegistrationAction.ETASJER_VEGGSONE_ADD: {
      const {
        payload: { veggId, veggsone }
      } = action;

      return {
        ...state,
        userInput: {
          ...state.userInput,
          bygningsdetaljer: {
            ...state.userInput?.bygningsdetaljer,
            etasjer: state.userInput?.bygningsdetaljer?.etasjer?.map(
              (etasje) => ({
                ...etasje,
                vegger: etasje.vegger?.map((vegg) =>
                  vegg.veggId === veggId
                    ? {
                        ...vegg,
                        veggSoner: [
                          ...vegg.veggSoner,
                          {
                            ...veggsone,
                            id: uuidv4()
                          }
                        ]
                      }
                    : vegg
                )
              })
            )
          }
        }
      };
    }

    case RegistrationAction.ETASJER_VEGG_SET_HAS_MULTIPLE_ZONES: {
      const {
        payload: { veggId, hasMultipleZones }
      } = action;

      return {
        ...state,
        userInput: {
          ...state.userInput,
          bygningsdetaljer: {
            ...state.userInput?.bygningsdetaljer,
            etasjer: state.userInput?.bygningsdetaljer?.etasjer?.map(
              (etasje) => ({
                ...etasje,
                vegger: etasje.vegger?.map((vegg) =>
                  vegg.veggId === veggId
                    ? {
                        ...vegg,
                        veggSoner: hasMultipleZones
                          ? [
                              ...vegg.veggSoner,
                              {
                                id: uuidv4(),
                                ...(state.allWallsAgainstTheOpen
                                  ? {
                                      tilliggendeRom: TilliggendeRom.MotDetFri
                                    }
                                  : {})
                              }
                            ]
                          : vegg.veggSoner?.splice(0, 1)?.map((vs) => ({
                              ...vs,
                              lengdeIMeter: vegg.lengdeIMeter
                            }))
                      }
                    : vegg
                )
              })
            )
          }
        }
      };
    }

    case RegistrationAction.ETASJER_VEGGSONE_REMOVE: {
      const {
        payload: { veggId }
      } = action;

      return {
        ...state,
        userInput: {
          ...state.userInput,
          bygningsdetaljer: {
            ...state.userInput?.bygningsdetaljer,
            etasjer: state.userInput?.bygningsdetaljer?.etasjer?.map(
              (etasje) => ({
                ...etasje,
                vegger: etasje.vegger?.map((vegg) =>
                  vegg.veggId === veggId
                    ? {
                        ...vegg,
                        veggSoner: vegg.veggSoner?.splice(
                          0,
                          vegg.veggSoner?.length - 1
                        )
                      }
                    : vegg
                )
              })
            )
          }
        }
      };
    }

    case RegistrationAction.ETASJER_VEGGSONE_UPDATE: {
      const {
        payload: { veggId, veggsone, veggsoneIndex }
      } = action;

      return {
        ...state,
        userInput: {
          ...state.userInput,
          bygningsdetaljer: {
            ...state.userInput?.bygningsdetaljer,
            etasjer: state.userInput?.bygningsdetaljer?.etasjer?.map(
              (etasje) => ({
                ...etasje,
                vegger: etasje.vegger?.map((vegg) => {
                  if (vegg.veggId !== veggId) {
                    return vegg;
                  }

                  return {
                    ...vegg,
                    veggSoner: vegg.veggSoner?.map((vs, vsi) => {
                      if (vsi !== veggsoneIndex) {
                        return vs;
                      }

                      return {
                        ...veggsone,
                        id: vs.id,
                        vinduer: vs.vinduer
                      };
                    })
                  };
                })
              })
            )
          }
        }
      };
    }

    case RegistrationAction.VINDU_ADD: {
      const {
        payload: { etasjeId, veggId, veggsoneId, windows: vindu }
      } = action;

      return {
        ...state,
        userInput: {
          ...state.userInput,
          bygningsdetaljer: {
            ...state.userInput?.bygningsdetaljer,
            etasjer: state.userInput?.bygningsdetaljer?.etasjer?.map((etasje) =>
              etasje.id === etasjeId
                ? {
                    ...etasje,
                    vegger: etasje.vegger?.map((vegg) =>
                      vegg.veggId === veggId
                        ? {
                            ...vegg,
                            veggSoner: vegg.veggSoner?.map((veggsone) =>
                              veggsone.id === veggsoneId
                                ? {
                                    ...veggsone,
                                    vinduer: [
                                      ...(veggsone.vinduer || []),
                                      ...vindu
                                    ]
                                  }
                                : veggsone
                            )
                          }
                        : vegg
                    )
                  }
                : etasje
            )
          }
        }
      };
    }

    case RegistrationAction.VINDU_REMOVE: {
      const {
        payload: { etasjeId, veggId, veggsoneId, vinduIds: vinduId }
      } = action;

      return {
        ...state,
        userInput: {
          ...state.userInput,
          bygningsdetaljer: {
            ...state.userInput?.bygningsdetaljer,
            etasjer: state.userInput?.bygningsdetaljer?.etasjer?.map((etasje) =>
              etasje.id === etasjeId
                ? {
                    ...etasje,
                    vegger: etasje.vegger?.map((vegg) =>
                      vegg.veggId === veggId
                        ? {
                            ...vegg,
                            veggSoner: vegg.veggSoner?.map((veggsone) =>
                              veggsone.id === veggsoneId
                                ? {
                                    ...veggsone,
                                    vinduer: veggsone.vinduer?.filter(
                                      (vindu) => !vinduId.includes(vindu.id)
                                    )
                                  }
                                : veggsone
                            )
                          }
                        : vegg
                    )
                  }
                : etasje
            )
          }
        }
      };
    }

    case RegistrationAction.VINDU_EDIT: {
      const {
        payload: { etasjeId, veggId, veggsoneId, windows: vindu, vinduIds }
      } = action;

      return {
        ...state,
        userInput: {
          ...state.userInput,
          bygningsdetaljer: {
            ...state.userInput?.bygningsdetaljer,
            etasjer: state.userInput?.bygningsdetaljer?.etasjer?.map((etasje) =>
              etasje.id === etasjeId
                ? {
                    ...etasje,
                    vegger: etasje.vegger?.map((vegg) =>
                      vegg.veggId === veggId
                        ? {
                            ...vegg,
                            veggSoner: vegg.veggSoner?.map((veggsone) => {
                              if (veggsone.id === veggsoneId) {
                                // Filter out all the windows that are to be edited and replace them with new ones
                                const filteredWindows =
                                  veggsone.vinduer?.filter(
                                    (w) => !vinduIds.includes(w.id)
                                  ) as Vindu[];

                                return {
                                  ...veggsone,
                                  vinduer: [...filteredWindows, ...vindu]
                                };
                              }

                              return veggsone;
                            })
                          }
                        : vegg
                    )
                  }
                : etasje
            )
          }
        }
      };
    }

    case RegistrationAction.GULVSONE_INIT: {
      return {
        ...state,
        userInput: {
          ...state.userInput,
          bygningsdetaljer: {
            ...state.userInput?.bygningsdetaljer,
            etasjer: state.userInput?.bygningsdetaljer?.etasjer?.map(
              (etasje) => ({
                ...etasje,
                gulvSoner:
                  etasje.gulvSoner?.length || 0 > 0
                    ? etasje.gulvSoner?.map((sone) => ({
                        ...sone,
                        id: sone.id || uuidv4()
                      }))
                    : [{ id: uuidv4() }]
              })
            )
          }
        }
      };
    }

    case RegistrationAction.GULVSONE_ADD: {
      const {
        payload: { etasjeId }
      } = action;

      return {
        ...state,
        userInput: {
          ...state.userInput,
          bygningsdetaljer: {
            ...state.userInput?.bygningsdetaljer,
            etasjer: state.userInput?.bygningsdetaljer?.etasjer?.map((etasje) =>
              etasje.id === etasjeId
                ? {
                    ...etasje,
                    gulvSoner: [...(etasje.gulvSoner || []), { id: uuidv4() }]
                  }
                : etasje
            )
          }
        }
      };
    }

    case RegistrationAction.GULV_SET_HAS_MULTIPLE_ZONES: {
      const {
        payload: { etasjeId, hasMultipleZones }
      } = action;

      return {
        ...state,
        userInput: {
          ...state.userInput,
          bygningsdetaljer: {
            ...state.userInput?.bygningsdetaljer,
            etasjer: state.userInput?.bygningsdetaljer?.etasjer?.map((etasje) =>
              etasje.id === etasjeId
                ? {
                    ...etasje,
                    gulvSoner: hasMultipleZones
                      ? [...(etasje.gulvSoner || []), { id: uuidv4() }]
                      : etasje.gulvSoner?.splice(0, 1) || [{ id: uuidv4() }]
                  }
                : etasje
            )
          }
        }
      };
    }

    case RegistrationAction.GULVSONE_REMOVE: {
      const {
        payload: { etasjeId }
      } = action;

      return {
        ...state,
        userInput: {
          ...state.userInput,
          bygningsdetaljer: {
            ...state.userInput?.bygningsdetaljer,
            etasjer: state.userInput?.bygningsdetaljer?.etasjer?.map((etasje) =>
              etasje.id === etasjeId
                ? {
                    ...etasje,
                    gulvSoner: etasje.gulvSoner?.splice(
                      0,
                      etasje.gulvSoner?.length - 1
                    ) || [{ id: uuidv4() }]
                  }
                : etasje
            )
          }
        }
      };
    }

    case RegistrationAction.GULVSONE_UPDATE: {
      const {
        payload: { etasjeId, value, field, gulvsoneId }
      } = action;

      const updatedState = {
        ...state,
        userInput: {
          ...state.userInput,
          bygningsdetaljer: {
            ...state.userInput?.bygningsdetaljer,
            etasjer: state.userInput?.bygningsdetaljer?.etasjer?.map((etasje) =>
              etasje.id === etasjeId
                ? {
                    ...etasje,
                    gulvSoner: etasje.gulvSoner?.map((sone) => {
                      if (sone.id !== gulvsoneId) return sone;

                      if (
                        field === 'tilliggendeRom' &&
                        value === TilliggendeRom.OppvarmetRom
                      )
                        delete sone.gulvKonstruksjon;

                      return { ...sone, [field]: value };
                    }) || [{ id: uuidv4(), [field]: value }]
                  }
                : etasje
            )
          }
        }
      };

      const maintainGrunnforhold =
        updatedState.userInput?.bygningsdetaljer?.etasjer?.some((etasje) =>
          etasje.gulvSoner?.some(
            (sone) => sone.tilliggendeRom === TilliggendeRom.MotGrunn
          )
        );

      if (!maintainGrunnforhold)
        delete updatedState.userInput?.bygningsdetaljer?.grunnforhold;

      return updatedState;
    }

    case RegistrationAction.GJENSTAENDE_TILTAK_ADD: {
      const {
        payload: { tiltak }
      } = action;

      return {
        ...state,
        energiplan: {
          ...state.energiplan,
          gjenstaendeTiltak: [
            ...(state.energiplan?.gjenstaendeTiltak || []),
            tiltak
          ]
        }
      };
    }

    case RegistrationAction.GJENSTAENDE_TILTAK_REMOVE: {
      const {
        payload: { tiltakId }
      } = action;

      return {
        ...state,
        energiplan: {
          ...state.energiplan,
          gjenstaendeTiltak: state.energiplan?.gjenstaendeTiltak?.filter(
            (tiltak) => tiltak.tiltakId != tiltakId
          )
        }
      };
    }

    case RegistrationAction.TAK_UPDATE: {
      const {
        payload: { field, value }
      } = action;

      return {
        ...state,
        userInput: {
          ...state.userInput,
          bygningsdetaljer: {
            ...state.userInput?.bygningsdetaljer,
            tak: {
              ...state.userInput?.bygningsdetaljer?.tak,
              [field]: value
            }
          }
        }
      };
    }

    case RegistrationAction.TAK_VINDU_ADD: {
      const {
        payload: { vindu }
      } = action;

      return {
        ...state,
        userInput: {
          ...state.userInput,
          bygningsdetaljer: {
            ...state.userInput?.bygningsdetaljer,
            tak: {
              ...state.userInput?.bygningsdetaljer?.tak,
              vinduer: [
                ...(state.userInput?.bygningsdetaljer?.tak?.vinduer || []),
                vindu
              ]
            }
          }
        }
      };
    }

    case RegistrationAction.TAK_VINDU_EDIT: {
      const {
        payload: { vindu }
      } = action;

      const { id, ...rest } = vindu;

      return {
        ...state,
        userInput: {
          ...state.userInput,
          bygningsdetaljer: {
            ...state.userInput?.bygningsdetaljer,
            tak: {
              ...state.userInput?.bygningsdetaljer?.tak,
              vinduer: state.userInput.bygningsdetaljer.tak?.vinduer?.map(
                (window) => (window.id === id ? { id, ...rest } : window)
              )
            }
          }
        }
      };
    }

    case RegistrationAction.TAK_VINDU_REMOVE: {
      const {
        payload: { vinduIds }
      } = action;

      // Since it is not possible to define several of the same roof window for now
      // we only need to retrieve the first id in this case.
      const vinduId = vinduIds?.[0];

      return {
        ...state,
        userInput: {
          ...state.userInput,
          bygningsdetaljer: {
            ...state.userInput?.bygningsdetaljer,
            tak: {
              ...state.userInput?.bygningsdetaljer?.tak,
              vinduer: state.userInput?.bygningsdetaljer?.tak?.vinduer?.filter(
                (vindu) => vindu.id !== vinduId
              )
            }
          }
        }
      };
    }

    case RegistrationAction.TOGGLE_ALL_FLOORS_SAME_SHAPE: {
      const {
        payload: { value }
      } = action;

      if (value) {
        const firstEtasjeItem = state.userInput?.bygningsdetaljer?.etasjer?.[0];
        const firstEtasjeCalculatedArea =
          state?.calculatedArea?.[firstEtasjeItem.id];

        return {
          ...state,
          // Remove all floors except the first one when all floors are toggled to be the same shape
          calculatedArea: {
            [firstEtasjeItem.id]: firstEtasjeCalculatedArea
          },
          allFloorsSameShape: true,
          userInput: {
            ...state.userInput,
            bygningsdetaljer: {
              ...state.userInput?.bygningsdetaljer,
              etasjer: state.userInput?.bygningsdetaljer?.etasjer?.map(
                (etasje, index) =>
                  index === 0
                    ? etasje
                    : {
                        ...etasje,
                        bygningsForm: firstEtasjeItem?.bygningsForm,
                        gjennomsnittligEtasjehoyde:
                          firstEtasjeItem?.gjennomsnittligEtasjehoyde,
                        vegger:
                          // Copy walls from first floor and make sure wall zones has unique ids
                          firstEtasjeItem?.vegger?.map((vegg) => ({
                            veggId: vegg.veggId,
                            himmelretning: vegg.himmelretning,
                            lengdeIMeter: vegg.lengdeIMeter,
                            veggSoner: vegg.veggSoner.map((veggsone) => ({
                              ...veggsone,
                              id: uuidv4()
                            }))
                          })) || []
                      }
              )
            }
          }
        };
      }

      return {
        ...state,
        allFloorsSameShape: false,
        allFloorsSameWallConstruction: false
      };
    }

    case RegistrationAction.TOGGLE_ALL_FLOORS_SAME_WALL_CONSTR: {
      const {
        payload: { value: allFloorsSameWallConstruction }
      } = action;

      // If all floors have same construction - use walls from floor index 0
      if (allFloorsSameWallConstruction) {
        const firstEtasjeItem = state.userInput?.bygningsdetaljer?.etasjer?.[0];

        return {
          ...state,
          allFloorsSameWallConstruction: true,
          userInput: {
            ...state.userInput,
            bygningsdetaljer: {
              ...state.userInput?.bygningsdetaljer,
              etasjer: state.userInput?.bygningsdetaljer?.etasjer?.map(
                (etasje, etasjeIndex) =>
                  etasjeIndex === 0
                    ? etasje
                    : {
                        ...etasje,
                        vegger: etasje.vegger?.map((vegg, veggIndex) => ({
                          ...vegg,
                          veggSoner: firstEtasjeItem?.vegger?.[
                            veggIndex
                          ]?.veggSoner?.map((veggsone) => ({
                            ...veggsone,
                            id: uuidv4()
                          })) || [{ id: uuidv4() }]
                        }))
                      }
              )
            }
          }
        };
      }

      return {
        ...state,
        allFloorsSameWallConstruction: false
      };
    }

    case RegistrationAction.TOGGLE_ALL_WALLS_AGAINST_THE_OPEN: {
      const {
        payload: { value }
      } = action;

      return value
        ? {
            ...state,
            allWallsAgainstTheOpen: true,
            userInput: {
              ...state.userInput,
              bygningsdetaljer: {
                ...state.userInput?.bygningsdetaljer,
                etasjer: state.userInput?.bygningsdetaljer?.etasjer?.map(
                  (etasje) =>
                    etasje.erKjeller
                      ? etasje
                      : {
                          ...etasje,
                          vegger: etasje.vegger?.map((vegg) => ({
                            ...vegg,
                            veggSoner: vegg.veggSoner?.map((vs) => ({
                              ...vs,
                              tilliggendeRom: TilliggendeRom.MotDetFri
                            }))
                          }))
                        }
                )
              }
            }
          }
        : {
            ...state,
            allWallsAgainstTheOpen: false
          };
    }

    case RegistrationAction.UPDATE_BYGNINGSDETALJERING: {
      const {
        payload: { field, value }
      } = action;

      return {
        ...state,
        userInput: {
          ...state.userInput,
          bygningsdetaljer: {
            ...state.userInput?.bygningsdetaljer,
            [field]: value
          }
        }
      };
    }

    case RegistrationAction.UPDATE_TERRENGSKJERMING: {
      const {
        payload: { field, value }
      } = action;

      return {
        ...state,
        userInput: {
          ...state.userInput,
          bygningsdetaljer: {
            ...state.userInput?.bygningsdetaljer,
            terrengskjerming: {
              ...state.userInput?.bygningsdetaljer?.terrengskjerming,
              [field]: value
            } as Terrengskjerming
          }
        }
      };
    }

    case RegistrationAction.UPDATE_ENERGIBRUK: {
      const {
        payload: {
          energibruk: { id, ar, energienhet, energikilde, forbruk }
        }
      } = action;

      if (forbruk != null)
        return {
          ...state,
          userInput: {
            ...state.userInput,
            energibruk: state.userInput?.energibruk?.find(
              (item) => item.ar === ar && item.energikilde === energikilde
            )
              ? state.userInput?.energibruk.map((item) =>
                  item.ar === ar && item.energikilde === energikilde
                    ? { ...item, forbruk }
                    : item
                )
              : [
                  ...(state.userInput?.energibruk || []),
                  {
                    id: id ?? uuidv4(),
                    ar,
                    energikilde,
                    forbruk,
                    energienhet
                  }
                ]
          }
        };

      return {
        ...state,
        userInput: {
          ...state.userInput,
          energibruk: (state.userInput?.energibruk || []).filter(
            (item) => !(item.ar === ar && item.energikilde === energikilde)
          )
        }
      };
    }

    case RegistrationAction.UPDATE_ENERGIBRUK_UNIT: {
      const {
        payload: { energienhet, energikilde }
      } = action;

      return {
        ...state,
        userInput: {
          ...state.userInput,
          energibruk: state.userInput?.energibruk?.map((item) =>
            item.energikilde === energikilde
              ? {
                  ...item,
                  energienhet
                }
              : item
          )
        }
      };
    }

    case RegistrationAction.UPDATE_KONSTRUKSJONSTETTHET: {
      const {
        payload: { data }
      } = action;

      const updatedKonstruksjonstetthet: Konstruksjonstetthet = {
        ...state.userInput?.bygningsdetaljer?.konstruksjonstetthet,
        dato: data.dato,
        lekkasjetall: data.lekkasjetall
      };

      return {
        ...state,
        userInput: {
          ...state.userInput,
          bygningsdetaljer: {
            ...state.userInput?.bygningsdetaljer,
            konstruksjonstetthet: {
              ...state.userInput?.bygningsdetaljer?.konstruksjonstetthet,
              ...updatedKonstruksjonstetthet
            }
          }
        }
      };
    }

    case RegistrationAction.UPDATE_DETAILING_STEPS: {
      const {
        payload: { step, value }
      } = action;

      if (value) {
        return {
          ...state,
          userInput: {
            ...state.userInput,
            detaljeringssteg: {
              ...state.userInput?.detaljeringssteg,
              [step]: value
            }
          }
        };
      }

      switch (step) {
        case DetailingStep.Bygningsform:
          delete state.userInput?.bygningsdetaljer?.terrengskjerming;

          return {
            ...state,
            userInput: {
              ...state.userInput,
              bygningsdetaljer: {
                ...state.userInput?.bygningsdetaljer,
                etasjer: state.userInput?.bygningsdetaljer?.etasjer?.map(
                  (etasje) => {
                    delete etasje.bygningsForm;
                    delete etasje.gjennomsnittligEtasjehoyde;
                    delete etasje.vegger;
                    return etasje;
                  }
                )
              },
              detaljeringssteg: {
                ...state.userInput?.detaljeringssteg,
                [step]: value
              }
            },
            allFloorsSameShape: false,
            allFloorsSameWallConstruction: false,
            calculatedArea: undefined
          };

        case DetailingStep.Vegger:
          return {
            ...state,
            userInput: {
              ...state.userInput,
              bygningsdetaljer: {
                ...state.userInput?.bygningsdetaljer,
                etasjer: state.userInput?.bygningsdetaljer?.etasjer?.map(
                  (etasje) => {
                    delete etasje.vegger;
                    return etasje;
                  }
                )
              },
              detaljeringssteg: {
                ...state.userInput?.detaljeringssteg,
                [step]: value
              }
            },
            allFloorsSameShape: false,
            allFloorsSameWallConstruction: false,
            calculatedArea: undefined
          };

        case DetailingStep.Vinduer:
          return {
            ...state,
            userInput: {
              ...state.userInput,
              bygningsdetaljer: {
                ...state.userInput?.bygningsdetaljer,
                etasjer: state.userInput?.bygningsdetaljer?.etasjer?.map(
                  (etasje) => {
                    etasje.vegger?.map((vegg) => {
                      vegg.veggSoner.map((veggsone) => {
                        delete veggsone.vinduer;
                        return veggsone;
                      });
                    });
                    return etasje;
                  }
                )
              },
              detaljeringssteg: {
                ...state.userInput?.detaljeringssteg,
                [step]: value
              }
            }
          };

        case DetailingStep.Gulv:
          return {
            ...state,
            userInput: {
              ...state.userInput,
              bygningsdetaljer: {
                ...state.userInput?.bygningsdetaljer,
                etasjer: state.userInput?.bygningsdetaljer?.etasjer?.map(
                  (etasje) => {
                    delete etasje.gulvSoner;
                    return etasje;
                  }
                )
              },
              detaljeringssteg: {
                ...state.userInput?.detaljeringssteg,
                [step]: value
              }
            }
          };

        case DetailingStep.Ytterdorer:
          delete state.userInput?.bygningsdetaljer?.ytterDorer;
          break;
        case DetailingStep.Energibruk:
          delete state.userInput?.energibruk;
          break;
        case DetailingStep.Lekkasjetall:
          delete state.userInput?.bygningsdetaljer?.konstruksjonstetthet;
          break;
        case DetailingStep.Takkonstruksjon:
          delete state.userInput?.bygningsdetaljer?.tak;
          break;
        default:
          return {
            ...state,
            userInput: {
              ...state.userInput,
              detaljeringssteg: {
                ...state.userInput?.detaljeringssteg,
                [step]: value
              }
            }
          };
      }

      return {
        ...state,
        userInput: {
          ...state.userInput,
          detaljeringssteg: {
            ...state.userInput?.detaljeringssteg,
            [step]: value
          }
        }
      };
    }

    case RegistrationAction.UPDATE_USER: {
      const {
        payload: { value }
      } = action;

      return {
        ...state,
        user: value
      };
    }

    case RegistrationAction.UPDATE_REGISTRATION_FIELD: {
      const {
        payload: { objectPath, value }
      } = action;

      const field = objectPath[objectPath.length - 1];

      if (field === 'solfangerMedVannbarenVarme' && !!value) {
        return {
          ...state,
          userInput: {
            ...state.userInput,
            tekniskUtstyr: {
              ...state.userInput?.tekniskUtstyr,
              oppvarmingSol: {
                ...state.userInput?.tekniskUtstyr?.oppvarmingSol,
                solfangerMedVannbarenVarme: {
                  serviceGjennomfortSiste2Ar: JaNeiVetIkke.VetIkke,
                  bruk: OppvarmingBruk.Ukjent
                }
              } as OppvarmingSol
            }
          }
        };
      }

      if (field === 'ventilasjonType') {
        if (value === VentilasjonsType.Balansert)
          return {
            ...state,
            userInput: {
              ...state.userInput,
              ventilasjon: {
                [field]: value as VentilasjonsType,
                serviceGjennomfortSiste2Ar: JaNeiVetIkke.VetIkke,
                gjenvinningAvVarme: JaNeiVetIkke.VetIkke
              }
            }
          };

        if (
          state.userInput?.ventilasjon?.ventilasjonType ===
          VentilasjonsType.Balansert
        )
          return {
            ...state,
            userInput: {
              ...state.userInput,
              ventilasjon: {
                [field]: value as VentilasjonsType
              }
            }
          };
      }

      if (
        field === 'gjenvinningAvVarme' &&
        state.userInput?.ventilasjon?.gjenvinningAvVarme === JaNeiVetIkke.Ja &&
        value !== JaNeiVetIkke.Ja
      ) {
        delete state.userInput?.ventilasjon?.gjenvinningsType;

        return {
          ...state,
          userInput: {
            ...state.userInput,
            ventilasjon: {
              ...state.userInput?.ventilasjon,
              [field]: value as JaNeiVetIkke
            }
          }
        };
      }

      if (
        field === 'varmeKilde' &&
        objectPath.includes('oppvarmingVarmepumpe')
      ) {
        if (
          value === Varmekilde.LuftFraVentilasjonsanlegg &&
          state.userInput?.ventilasjon?.ventilasjonType &&
          ![
            VentilasjonsType.Balansert,
            VentilasjonsType.MekaniskAvtrekk
          ].includes(state.userInput?.ventilasjon?.ventilasjonType)
        )
          delete state.userInput?.ventilasjon?.ventilasjonType;

        return {
          ...state,
          userInput: {
            ...state.userInput,
            tekniskUtstyr: {
              ...state.userInput?.tekniskUtstyr,
              oppvarmingVarmepumpe: {
                [field]: value as Varmekilde
              }
            }
          }
        };
      }

      if (field === 'oppvarmingVarmepumpe.varmeKilde.type') {
        delete state.userInput?.tekniskUtstyr?.oppvarmingVarmepumpe
          ?.punktoppvarming;

        delete state.userInput?.tekniskUtstyr?.oppvarmingVarmepumpe
          ?.vannbarenVarme;

        delete state.userInput?.tekniskUtstyr?.oppvarmingVarmepumpe
          ?.ventilasjon;

        return {
          ...state,
          userInput: {
            ...state.userInput,
            tekniskUtstyr: {
              ...state.userInput?.tekniskUtstyr,
              oppvarmingVarmepumpe: {
                ...state.userInput?.tekniskUtstyr?.oppvarmingVarmepumpe,
                [value as keyof OppvarmingVarmepumpe]: {
                  serviceGjennomfortSiste2Ar: JaNeiVetIkke.VetIkke,
                  ...(value === 'vannbarenVarme' || value === 'ventilasjon'
                    ? { bruk: OppvarmingBruk.Ukjent }
                    : {}),
                  ...(value === 'ventilasjon'
                    ? { varmesentral: Varmesentral.Ukjent }
                    : {})
                }
              }
            }
          }
        };
      }

      if (field === 'byggType') {
        delete state.userInput?.bygningsdetaljer
          ?.plasseringAvEnhetIByggHorisontalt;
        delete state.userInput?.bygningsdetaljer
          ?.plasseringAvEnhetIByggVertikalt;

        return {
          ...state,
          userInput: {
            ...state.userInput,
            bygningsdetaljer: {
              ...state.userInput?.bygningsdetaljer,
              [field]: value as ByggType
            }
          }
        };
      }

      if (
        field === 'plasseringAvEnhetIByggVertikalt' &&
        value !== PlasseringAvEnhetIByggVertikalt.OversteEtasje
      ) {
        delete state.userInput?.bygningsdetaljer?.tak;

        return {
          ...state,
          userInput: {
            ...state.userInput,
            bygningsdetaljer: {
              ...state.userInput?.bygningsdetaljer,
              [field]: value as PlasseringAvEnhetIByggVertikalt
            },
            detaljeringssteg: {
              ...state.userInput?.detaljeringssteg,
              [DetailingStep.Takkonstruksjon]: false
            }
          }
        };
      }

      if (
        [
          'antallEtasjerUnntattKjeller',
          'basement',
          'harBoligenKjeller'
        ].includes(field)
      ) {
        let etasjer = state.userInput?.bygningsdetaljer?.etasjer;
        // Delete all state related to calculation of area when data related to etasjer changes.
        // Not proud of this but it is the best of two evils.
        delete state.calculatedArea;

        if (field === 'antallEtasjerUnntattKjeller') {
          if (value == null) {
            delete state.userInput?.bygningsdetaljer
              ?.antallEtasjerUnntattKjeller;

            return {
              ...state,
              userInput: {
                ...state.userInput,
                bygningsdetaljer: {
                  ...state.userInput?.bygningsdetaljer,
                  etasjer: etasjer?.filter(({ erKjeller }) => erKjeller)
                }
              }
            };
          }

          const numericValue = value as number;

          const nonBasementFloorCount =
            state.userInput?.bygningsdetaljer?.etasjer?.filter(
              (etasje) => !etasje.erKjeller
            )?.length || 0;

          if (numericValue != null) {
            if (numericValue > nonBasementFloorCount) {
              const firstFloor =
                state.userInput?.bygningsdetaljer?.etasjer?.[0];

              const inheritedValues: Partial<Etasje> = state.allFloorsSameShape
                ? {
                    bygningsForm: firstFloor?.bygningsForm,
                    gjennomsnittligEtasjehoyde:
                      firstFloor?.gjennomsnittligEtasjehoyde,
                    vegger: firstFloor?.vegger?.map(({ veggSoner, ...rest }) =>
                      state.allFloorsSameWallConstruction
                        ? {
                            ...rest,
                            veggSoner: veggSoner.map(
                              // eslint-disable-next-line
                              ({ vinduer, ...veggsoneAttributes }) => ({
                                ...veggsoneAttributes,
                                id: uuidv4()
                              })
                            )
                          }
                        : {
                            ...rest,
                            veggSoner: [
                              {
                                id: uuidv4(),
                                ...(state.allWallsAgainstTheOpen
                                  ? { tilliggendeRom: TilliggendeRom.MotDetFri }
                                  : {})
                              }
                            ]
                          }
                    )
                  }
                : {};

              etasjer = [
                ...(state.userInput?.bygningsdetaljer?.etasjer || []),
                ...Array.from(
                  Array(numericValue - nonBasementFloorCount).keys()
                ).map((i) => ({
                  id: uuidv4(),
                  erKjeller: false,
                  etasjeNummer: nonBasementFloorCount + i + 1,
                  ...inheritedValues
                }))
              ];
            } else if (numericValue < nonBasementFloorCount)
              etasjer = (etasjer || []).filter(
                (etasje) =>
                  etasje.erKjeller ||
                  (!etasje.erKjeller && etasje.etasjeNummer <= numericValue)
              );
          }

          return {
            ...state,
            userInput: {
              ...state.userInput,
              bygningsdetaljer: {
                ...state.userInput?.bygningsdetaljer,
                etasjer: etasjer,
                [field]: numericValue
              }
            }
          };
        }

        if (
          field === 'basement' ||
          (field === 'harBoligenKjeller' && value === false)
        ) {
          const booleanValue = value as boolean;

          const basement = state.userInput?.bygningsdetaljer?.etasjer?.find(
            (etasje) => etasje.erKjeller
          );

          if (booleanValue && !basement) {
            const firstFloor = state.userInput?.bygningsdetaljer?.etasjer?.[0];

            const inheritedValues: Partial<Etasje> = state.allFloorsSameShape
              ? {
                  bygningsForm: firstFloor?.bygningsForm,
                  gjennomsnittligEtasjehoyde:
                    firstFloor?.gjennomsnittligEtasjehoyde,
                  vegger: firstFloor?.vegger?.map(({ veggSoner, ...rest }) =>
                    state.allFloorsSameWallConstruction
                      ? {
                          ...rest,
                          veggSoner: veggSoner.map(
                            // eslint-disable-next-line
                            ({ vinduer, ...veggsoneAttributes }) => ({
                              ...veggsoneAttributes,
                              id: uuidv4()
                            })
                          )
                        }
                      : {
                          ...rest,
                          veggSoner: [
                            {
                              id: uuidv4(),
                              ...(state.allWallsAgainstTheOpen
                                ? { tilliggendeRom: TilliggendeRom.MotDetFri }
                                : {})
                            }
                          ]
                        }
                  )
                }
              : {};

            etasjer = [
              {
                id: uuidv4(),
                erKjeller: true,
                etasjeNummer: 1,
                ...inheritedValues
              },
              ...(etasjer || [])
            ];
          }

          let heleEllerDelerAvKjellerErOppvarmet = false;

          if (!booleanValue && basement)
            etasjer = etasjer?.filter((etasje) => !etasje.erKjeller);

          if (booleanValue && field === 'basement') {
            heleEllerDelerAvKjellerErOppvarmet = true;
          }

          return {
            ...state,
            userInput: {
              ...state.userInput,
              bygningsdetaljer: {
                ...state.userInput?.bygningsdetaljer,
                heleEllerDelerAvKjellerErOppvarmet,
                etasjer: etasjer,
                ...(field === 'harBoligenKjeller'
                  ? { [field]: booleanValue }
                  : {})
              }
            }
          };
        }
      }

      if (field === 'harBoligenKjeller' && value === true)
        delete state.userInput?.bygningsdetaljer
          ?.tilliggendeRomGulvIForsteEtasje;

      if (TekniskUtstyrKeys.includes(field as TekniskUtstyrKey) && !value) {
        const energySource =
          energySourceUnitMap[field as TekniskUtstyrKey]?.energySource;

        if (energySource && state.userInput?.energibruk)
          return {
            ...state,
            userInput: {
              ...recursivelyHandleNestedChange<UserInput>(
                state.userInput,
                objectPath,
                value
              ),
              energibruk: state.userInput?.energibruk.filter(
                (item) => item.energikilde !== energySource
              )
            }
          };
      }

      return {
        ...state,
        userInput: recursivelyHandleNestedChange<UserInput>(
          state.userInput,
          objectPath,
          value
        )
      };
    }
    default:
      return state;
  }
};

export default registerReducer;
